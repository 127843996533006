import Image from 'next/image';
import { useContext, useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import AppContext from '../../store/AppContext';

const Support = () => {
    const { branchs } = useContext(AppContext);
    const [isOpen, setIsOpen] = useState(false);
    const [activeId, setActiveId] = useState(branchs && branchs?.length > 0 ? branchs?.[0]?.id : 0);


    function reduceTooLongString(string: string) {
        if (string.length > 10) {
            string = string.slice(0, 10) + "...";
        }

        return string;
    }

    const Item = ({ item }: any) => {
        const ref: any = useRef(null);
        return <div className={`
        text-base 
        ${activeId === item.id ? "font-[550]" : "font-[500]"} 
        text-[#434343] 
        cursor-pointer
        m-0
        mr-2
        `}
            ref={ref}
            onClick={() => {
                ref?.current?.scrollIntoView({ behavior: "smooth", block: "center", inline: 'center' });
                setActiveId(item.id)
            }}
        >
            {reduceTooLongString(item.name) ?? ""}
        </div>
    }

    useEffect(() => {
        const scrollContainer: any = document.getElementById("horizontal-scroll-wrapper");

        scrollContainer?.addEventListener("wheel", (evt: any) => {
            evt.preventDefault();
            scrollContainer.scrollLeft += evt.deltaY;
        });
    }, [])

    return (<>
        {isOpen === false && <Image
            onClick={() => setIsOpen(!isOpen)}
            className={"fixed w-[64px] h-[64px] top-[80%] lg:top-[80%] right-[24px] z-[20] cursor-pointer"}
            src={"/asset/image/support.png"}
            alt={"support"}
            width={64}
            height={64}
        />}
        {
            isOpen === true && <div id='support_popup' className='fixed top-[60%] lg:top-[66%] right-[24px] bg-white  rounded-[8px] p-4 flex flex-col z-[21]'>
                <div className='max-h-[300px] lg:max-h-[300px] overflow-x-hidden overflow-y-auto w-full h-full gap-[8px] flex flex-col'>
                    <div id={"horizontal-scroll-wrapper"} className='m-4 h-auto pl-4 w-[148px]  ml-0 horizontal-scroll-wrapper hide_scrollbar lg:flex'>
                        {
                            branchs?.map((item: any) => {
                                return <Item key={item.id} item={item} />
                            })
                        }
                    </div>

                    <Link rel="noopener noreferrer" target='_blank' href={"https://m.me/hoaigiangshop"} className='min-h-[38px] mt-[26px] flex flex-row py-[9px] px-[10px] gap-[6px] bg-[#1890FF] items-center rounded-[8px]'>
                        <Image
                            className={"relative w-[16px] h-[16px]"}
                            src={"/asset/icon/ico_messenger_white.svg"}
                            alt={"messenger"}
                            width={16}
                            height={16}
                        />
                        <p className='text-sm text-[#fff]'>Chat messenger</p>
                    </Link>
                    {
                        branchs?.find((e: any) => e.id === activeId)?.phone?.map((phone: string) => {
                            return <Link href={"https://zalo.me/" + phone} rel="noopener noreferrer" target='_blank' key={phone} className='min-h-[38px] flex flex-row py-[9px] px-[10px] gap-[6px] bg-[#389E0D] items-center rounded-[8px]'>
                                <Image
                                    className={"relative overflow-hidden bg-white w-[16px] h-[16px] z-[30]"}
                                    src={"/asset/image/zalo.webp"}
                                    alt={"zalo"}
                                    width={16}
                                    height={16}
                                />
                                <p className='text-sm text-[#fff]'>Gọi {phone}</p>
                            </Link>
                        })
                    }
                    {
                        branchs?.find((e: any) => e.id === activeId)?.phone?.map((phone: string) => {
                            return <div key={"phone_" + phone} className='flex lg:hidden min-h-[38px] flex flex-row py-[9px] px-[10px] gap-[6px] bg-[#389E0D] items-center rounded-[8px]'>
                                <Image
                                    className={"relative w-[16px] h-[16px]"}
                                    src={"/asset/icon/ico_calling.svg"}
                                    alt={"calling"}
                                    width={16}
                                    height={16}
                                />
                                <p className='text-sm text-[#fff]'>Gọi {phone}</p>
                            </div>
                        })
                    }
                </div>

                <Image
                    onClick={() => setIsOpen(!isOpen)}
                    className={"absolute w-[16px] h-[16px] right-[-5px] top-[-5px] z-[23] cursor-pointer"}
                    src={"/asset/image/close_circle_support.png"}
                    alt={"Close"}
                    width={16}
                    height={16}
                />
            </div>
        }
    </>);
}

export default Support;