import { PropsWithChildren, Suspense } from 'react'
//import FooterComponent from './Footer'
import HeaderComponent from './Header'
import Support from '../Support';
import dynamic from 'next/dynamic';

const DynamicFooter = dynamic(() => import('./Footer'));

type TLayoutProps = PropsWithChildren & {
    props?: any,
    className?: string,
    isShowFooter?: boolean,
    isLoading?: boolean
}

const LayoutComponent = ({ children, className, isShowFooter = true, isLoading = true, props }: TLayoutProps) => {
    return (
        <main {...props} className={className}>
            <HeaderComponent />

            <div className='body mt-[50px] md:mt-[64px]'>
                {children}
            </div>

            <Support />
            {(!isLoading) && <DynamicFooter />}
        </main>
    )
}

export default LayoutComponent